import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ServicesList = () => {
  const data = useStaticQuery(graphql`
    query ServicesPageQuery {
      allServicesPageDataJson {
        edges {
          node {
            blurb
            name
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1
                  quality: 100
                  webpOptions: { quality: 100 }
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ServicesContainer>
      {data.allServicesPageDataJson.edges.map((item, index) => (
        <Service key={index} no={index}>
          <Text no={index}>
            <Name>{item.node.name}</Name>
            <Blurb dangerouslySetInnerHTML={{ __html: item.node.blurb }} />
          </Text>
          <Image
            image={getImage(item.node.image.childImageSharp.gatsbyImageData)}
            alt={item.node.name}
          />
        </Service>
      ))}
    </ServicesContainer>
  )
}

export default ServicesList

const ServicesContainer = styled.div`
  margin: 4rem 0;
  background-color: #f9f9fb;
`

const Service = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  margin-bottom: 3rem;

  @media screen and (min-width: 840px) {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    flex-direction: ${({ no }) => (no % 2 === 0 ? 'row-reverse' : 'row')};
    margin-bottom: 0rem;
  }
`

const Text = styled.div`
  padding: 0 1rem 1rem;
  @media screen and (min-width: 840px) {
    width: 50%;
  }

  @media screen and (min-width: 1120px) {
    padding: 0 3rem;
  }
`

const Name = styled.h2`
  margin-bottom: 1rem;
  font-size: var(--step-1);
  margin-top: 2rem;

  @media screen and (min-width: 840px) {
    margin-top: 1rem;
  }
`

const Blurb = styled.p``

const Image = styled(GatsbyImage)`
  @media screen and (min-width: 840px) {
    width: 50%;
  }
`
