import React from 'react'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ServicesList from '../components/ServicesList'
import { PageTitle } from '../components/Titles'

const services = () => {
  return (
    <Layout>
      <Seo title='Services' />
      <PageTitle>Services</PageTitle>
      <ServicesList />
      <ContactForm />
    </Layout>
  )
}

export default services
